// Imports
import React from "react";
import {
  Card,
  Media,
  Container,
  Content,
  Heading,
  Button,
} from "react-bulma-components";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

// Styled components

const ContentStyle = styled(Content)`
  text-align: justify;
  height: 170px;
`;

const ButtonStyle = styled(Button)`
  text-align: justify;
`;

const HeadStyle = styled(Heading)`
  text-align: justify;
  font-size: 40px;
  margin-left: 10px;
`;

const ParagraphStyle = styled.p`
  text-align: justify;
  font-size: 20px;
  margin-bottom: 50px;
`;

//Div CSS definitions
const divStyle = {
  // marginLeft: "50px",
  flex: 1,
  alignContentr: "stretch",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
};

const cardStyle = {
  margin: "20px",
  maxWidth: "300px",
  width: "300px",
  height: "350px",
  maxHeight: "350px",
};

function Projects() {
  const { t } = useTranslation();

  return (
    // All components inside a container
    <Container>
      <HeadStyle>{t("Projetos")}</HeadStyle> {/* Page's main heading */}
      <ParagraphStyle>{t("ProjetosTxt")}</ParagraphStyle>{" "}
      {/* Page's description */}
      <div style={divStyle}>
        <div>
          <Card style={cardStyle}>
            <Card.Content>
              <Media>
                <Media.Item>
                <Link
                  style={{ color: "black" }}
                  to="/projetos-andamento/justica-40"
                >
                  <Heading size={4}>{t("Just40")}</Heading>
                </Link>{" "}
                  {/* Card heading */}
                </Media.Item>
              </Media>
              <ContentStyle style={{overflow: 'hidden'}}>
                {" "}
                {/* Project description */}
                <span>

                {t("Just40Descricao")}
                </span>
              </ContentStyle>
              <Link to="/projetos-concluidos/pedro">
                {/* Link button */}
                <ButtonStyle color="dark" outlined={true} rounded={true}>
                  {t("VerMais")}
                </ButtonStyle>
              </Link>
            </Card.Content>
          </Card>
        </div>
        
        <div>
          <Card style={cardStyle}>
            <Card.Content>
              <Media>
                <Media.Item>
                  <Link
                    style={{ color: "black" }}
                    to="/projetos-concluidos/victor"
                  >
                    <Heading size={4}>{t("ProjVictor")}</Heading>
                  </Link>{" "}
                  {/* Card heading */}
                </Media.Item>
              </Media>
              <ContentStyle style={{overflow: 'hidden'}}>
                {" "}
                {/* Project description */}
                {t("ResVictor")}
              </ContentStyle>
              <Link to="/projetos-concluidos/victor">
                {/* Link button */}
                <ButtonStyle color="dark" outlined={true} rounded={true}>
                  {t("VerMais")}
                </ButtonStyle>
              </Link>
            </Card.Content>
          </Card>
        </div>
        <div>
          <Card style={cardStyle}>
            <Card.Content>
              <Media>
                <Media.Item>
                  <Link
                    style={{ color: "black" }}
                    to="/projetos-concluidos/alei"
                  >
                    <Heading  style={{fontSize: `1.25rem`}}>{t("Alei")}</Heading>
                  </Link>{" "}
                  {/* Card heading */}
                </Media.Item>
              </Media>
              <ContentStyle>
                {" "}
                {/* Project description */}
                {t("ResAlei")}
              </ContentStyle>
              <Link to="/projetos-concluidos/alei">
                {/* Link button */}
                <Button color="dark" outlined={true} rounded={true}>
                  {t("VerMais")}
                </Button>
              </Link>
            </Card.Content>
          </Card>
        </div>
        <div>
          <Card style={cardStyle}>
            <Card.Content>
              <Media>
                <Media.Item>
                  <Link
                    style={{ color: "black" }}
                    to="/projetos-concluidos/mandamus"
                  >
                    <Heading size={4}>{t("Mandamus")}</Heading>
                  </Link>{" "}
                  {/* Card heading */}
                </Media.Item>
              </Media>
              <ContentStyle style={{overflow: 'hidden'}}>
                {" "}
                {/* Project description */}
                {t("MandamusDesc")}
              </ContentStyle>
              <Link to="/projetos-concluidos/mandamus">
                {/* Link button */}
                <Button color="dark" outlined={true} rounded={true}>
                  {t("VerMais")}
                </Button>
              </Link>
            </Card.Content>
          </Card>
        </div>
        <div>
          <Card style={cardStyle}>
            <Card.Content>
              <Media>
                <Media.Item>
                  <Link
                    style={{ color: "black" }}
                    to="/projetos-concluidos/lavrador"
                  >
                    <Heading size={4}>{t("ProjLavrador")}</Heading>
                  </Link>{" "}
                  {/* Card heading */}
                </Media.Item>
              </Media>
              <ContentStyle>
                {" "}
                {/* Project description */}
                {t("ResLavrador")}
              </ContentStyle>
              <Link to="/projetos-concluidos/lavrador">
                {/* Link button */}
                <Button color="dark" outlined={true} rounded={true}>
                  {t("VerMais")}
                </Button>
              </Link>
            </Card.Content>
          </Card>
        </div>
        <div>
          <Card style={cardStyle}>
            <Card.Content>
              <Media>
                <Media.Item>
                  <Link
                    style={{ color: "black" }}
                    to="/projetos-concluidos/pe-diabetico"
                  >
                    <Heading size={4}>{t("ProjPe")}</Heading>
                  </Link>{" "}
                  {/* Card heading */}
                </Media.Item>
              </Media>
              <ContentStyle>
                {" "}
                {/* Project description */}
                {t("ResPe")}
                <br />
                <br />
              </ContentStyle>
              <Link to="/projetos-concluidos/pe-diabetico">
                {/* Link button */}
                <Button color="dark" outlined={true} rounded={true}>
                  {t("VerMais")}
                </Button>
              </Link>
            </Card.Content>
          </Card>
        </div>
        <div>
          <Card style={cardStyle}>
            <Card.Content>
              <Media>
                <Media.Item>
                  <Link
                    style={{ color: "black" }}
                    to="/projetos-concluidos/mamografia"
                  >
                    <Heading size={4}>{t("Mamografia")}</Heading>
                  </Link>{" "}
                  {/* Card heading */}
                </Media.Item>
              </Media>
              <ContentStyle>
                {" "}
                {/* Project description */}
                {t("ResMamografia")}
              </ContentStyle>
              <Link to="/projetos-concluidos/mamografia">
                {/* Link button */}
                <Button color="dark" outlined={true} rounded={true}>
                  {t("VerMais")}
                </Button>
              </Link>
            </Card.Content>
          </Card>
        </div>
        <div>
          <Card style={cardStyle}>
            <Card.Content>
              <Media>
                <Media.Item>
                  <Link
                    style={{ color: "black" }}
                    to="/projetos-concluidos/lesao-de-pele"
                  >
                    <Heading size={4}>{t("LesaoPele")}</Heading>
                  </Link>{" "}
                  {/* Card heading */}
                </Media.Item>
              </Media>
              <ContentStyle>
                {" "}
                {/* Project description */}
                {t("ResLesaoPele")}
              </ContentStyle>
              <Link to="/projetos-concluidos/lesao-de-pele">
                {/* Link button */}
                <Button color="dark" outlined={true} rounded={true}>
                  {t("VerMais")}
                </Button>
              </Link>
            </Card.Content>
          </Card>
        </div>
      </div>
    </Container>
  );
}

export default Projects;
