import React from "react";
import {
  Card,
  Media,
  Heading,
  Button,
  Container,
} from "react-bulma-components";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  HeadStyle,
  ParagraphStyle,
  ContentStyle,
  cardStyle,
  ContentWrapper,
} from "./style.js";

export function ProjectsInProgress() {
  const { t } = useTranslation();
  return (
    <Container>
      <HeadStyle>{t("Projetos")}</HeadStyle>
      <ParagraphStyle>{t("ProjetosTxtEmDesenvolvimento")}</ParagraphStyle>
      <ContentWrapper>
        {/* <Card style={cardStyle}>
          <Card.Content> */}
            {/* <Media>
              <Media.Item>
                <Link
                  style={{ color: "black" }}
                  to="/projetos-andamento/justica-40"
                >
                  <Heading size={4}>{t("Just40")}</Heading>
                </Link>{" "}
              </Media.Item>
            </Media>
            <ContentStyle> */}
              {/* {t("Just40Descricao")} */}
            {/* </ContentStyle> */}
            {/* <Link to="/projetos-andamento/justica-40">
              <Button color="dark" outlined={true} rounded={true}> */}
                {/* {t("VerMais")} */}
              {/* </Button>
            </Link>
          </Card.Content>
        </Card> */}

        <Card style={cardStyle}>
          <Card.Content>
            <Media>
              <Media.Item>
                <Link style={{ color: "black" }} to="/projetos-andamento/sabia">
                  <Heading size={4}>{t("Sabia")}</Heading>
                </Link>
              </Media.Item>
            </Media>
            <ContentStyle>
              {" "}
              {/* Project description */}
              {t("SabiaDescricao")}
            </ContentStyle>
            <Link to="/projetos-andamento/sabia">
              {/* Link button */}
              <Button color="dark" outlined={true} rounded={true}>
                {t("VerMais")}
              </Button>
            </Link>
          </Card.Content>
        </Card>

        {/* Mudar rotas dos cards a partir daqui */}
        <Card style={cardStyle}>
          <Card.Content>
            <Media>
              <Media.Item>
                <Link
                  style={{ color: "black" }}
                  to="/projetos-andamento/alei-1a"
                >
                  <Heading size={4}>{t("Alei1a")}</Heading>
                </Link>
              </Media.Item>
            </Media>
            <ContentStyle>
              {" "}
              {/* Project description */}
              {t("Alei1aDescricao")}
            </ContentStyle>
            <Link to="/projetos-andamento/alei-1a">
              {/* Link button */}
              <Button color="dark" outlined={true} rounded={true}>
                {t("VerMais")}
              </Button>
            </Link>
          </Card.Content>
        </Card>

        <Card style={cardStyle}>
          <Card.Content>
            <Media>
              <Media.Item>
                <Link style={{ color: "black" }} to="/projetos-andamento/sabia">
                  <Heading size={4}>{t("Osiris")}</Heading>
                </Link>
              </Media.Item>
            </Media>
            <ContentStyle>
              {" "}
              {/* Project description */}
              {t("OsirisDescricao")}
            </ContentStyle>
            <Link to="/projetos-andamento/osiris">
              {/* Link button */}
              <Button color="dark" outlined={true} rounded={true}>
                {t("VerMais")}
              </Button>
            </Link>
          </Card.Content>
        </Card>
      </ContentWrapper>
    </Container>
  );
}
