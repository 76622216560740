const En = {
  translation: {
    Inicio: "Home",
    Projetos: "Projects",
    Forum: "Forum",
    Todos: "All",
    Noticias: "News",
    Equipe: "Team",
    Editais: "Calls",
    Parcerias: "Partnerships",
    BemVindo: "WELCOME TO AI.LAB",
    Info: "Artificial Intelligence Laboratory of the University of Brasilia",
    AilabDesc: "AiLAB's mission is to promote research, development, teaching, and outreach in the fields of Artificial Intelligence and Data Science, expanding its presence across different sectors of society.",
    Intro:
      "The mission of Artificial Intelligence Laboratory is to promote research, development and extension in the areas of Artificial Intelligence and Data Science, expanding its insertion in the various sectors of society. We use a combination of approaches and technologies that we have developed in our investigations. Find out more about our research and study areas below.",
    Publicacoes: "Latest Publications",
    Acesso: "Access",
    PublicacoesTexto:
      "Here we make available the most recent publications, but you can see previous publications by accessing our coordinators profile!",
    PublicacoesT: "Would you like to see more?",
    LabGama: "UnB Gama inaugurates Artificial Intelligence Laboratory",
    TRF1: "TRF1 and UnB sign partnership to develop the Alei Artificial Intelligence tool",
    Ministra:
      "Minister Cármen Lúcia announces the start of the Victor Project, of artificial intelligence",
    VerMais: "More",
    Resultado: "Result",
    Visualizar: "To View",
    ConhecaTime: "Meet our team!",
    TimeMsg: "It would be impossible without them!",
    SaibaMais: "Know more",
    EquipeTxt:
      "Group of Researchers, Academics, Technicians in the development of research, technologies, extensions, and teaching related to the Artificial Intelligence area.",
    DatasetTxt: "Datasets used in the execution of Ai.Lab projects.",
    ProjetosTxt:
      "Projects developed by the AI.lab team of researchers and teachers.",
    ProjVictor: "Victor Project",
    ResVictor: `This project applies machine learning methods to recognize patterns in legal processes relating to the general repercussion of the Supremo Tribunal Federal - STF.`,
    TxtVictor: `The object of research and development (R&D) of this 
    project is to apply machine learning methods in order to use its 
    potentials in pattern recognition in legal processes 
    related to judgments of general repercussion of the Supreme Court - 
    STF. The goal is to develop a system composed of 
    deep machine learning algorithms that enable the automation of textual
     analyzes of these legal processes. This will be done with the creation 
     of machine learning models for analyzing the resources received by the 
     Federal Supreme Court regarding the most recurring General Repercussion 
     themes, with the objective of integrating the Court's solutions to 
     assist the servers responsible for analyzing the resources received at 
     identified related topics.`,
    ProjLavrador: "Lavrador Project",
    ResLavrador: `This project uses one of the best machine learning classification methods for assigning crops through images and multispectral data from Landsat satellite.`,
    TxtLavrador: `The research and development (R&D) project Lavrador is to assist the classification of crops with multispectral images from the Landsat satellite. One of the best methods for classifying images of machine learning (Conventional Institutional Neural Networks) was applied. The Lavrador allows to classify 7 different types of crops (Cotton, Rice, Sugarcane, Orange, Maize, Soy, Grape) and the "Others" class based on the differences in spectral signatures, with a structure that allows learning new types of crops. The objective of the research is to contribute to agronomic development with the help of technology to identify crops with geospatial images.`,
    ProjPe: "Diabetic foot",
    ResPe: `This project seeks technological tools capable of conducting the progression of the diabetic foot wound and complementing the diagnosis regarding ulcer classification.`,
    TituloPe: `Classification of diabetic foot ulcers using machine learning applied to image sequencing`,
    TxtPe: `The project seeks technological tools capable of leading the transformation of the diabetic foot wound and complementing the diagnosis regarding the classification of the ulcer. In this sense, we intend to develop and evaluate an automatic system for the detection and quantification of central aspects of diabetic foot ulcers (UPDs) using automatic learning techniques (Machine Learning), based on images of the wound. These aspects include granulation of the lesion, presence or absence of fibrin, among others, which are essential to determine the severity of the ulcer. It is expected to be easy to use in order to be handled by the patients and family members, who will be able to generate their images and automatically, with the system to be able to identify a classification for a specific wound following the University of Texas Classification protocol. Still being possible in the future, to provide alarm conditions together with an integrated structure of patient, professional or hospital data, allowing risk situations to be properly prioritized.`,
    TxtPe2: `The following images are small samples that will be used at work, referring to a diabetic foot injury treated with the Rapha equipment. Wounds are classified into three distinct phases and ulcer aspects that vary from one image to the next. The automatic method created must be able to distinguish and classify such aspects.`,
    Mamografia: "Mammography",
    ResMamografia: `This project applies methods and techniques to make it possible to recognize malignant and benign lesions on mammograms, as well as to detect their types.`,
    TituloMamografia: `Detection of lesions on mammography`,
    TxtMamografia: `The object of this research and development (R&D) project is to apply machine learning methods and techniques, so that it is possible to recognize malignant and benign lesions on mammography, as well as to detect which type. The system aims to be done with Convolutional Neural Network algorithms, whose derivation comes from the Deep Machine Learning technique. The aim is to automate the recognition of injuries so that when loading an image, the system identifies itself whether or not there is any injury, and, if so, what is its classification. The source of input is a file of a digital mammography, contained in the computer of the radiologist who will perform the diagnosis, so that this professional is assisted in his work, especially in cases where the diagnosis is more difficult for human discernment, the system will point out areas on mammograms that the radiologist should observe more carefully, due to detected injuries.`,
    LesaoPele: "Skin Lesion",
    ResLesaoPele: `This project used open databases and advanced techniques to train a deep neural network that contributes to the diagnosis of skin cancer.`,
    TxtPele: `Skin cancer plagues more than 14.1 million patients and has been the cause of more than 8.2 million deaths worldwide. Having a tool capable of identifying early cases within reach of patients can result in more lives saved and less treatment expenditures. The incidence of skin cancers has been gradually increasing over the years. In the 2018-2019 biennium alone, there was an estimated 165,000 new cases of non-melanoma skin cancer. Cases detected and treated within the early stages may have a 97% chance of survival, compared to 15%, if done later in advanced stages. In addition, the public safe, which today spends R $ 37 million on the health system, can benefit from simpler and shorter treatments.`,
    TxtPele2: `Using open databases and advanced techniques, we have been able to achieve results in the state of the art, following results obtained at Stanford and Seoul University. Altogether 88 thousand images were used to train a deep neural network, which in the end obtained at its worst 89% of positive positive about false positive (how accurate the diagnosis is). What becomes more significant when compared to the average accuracy of 78% of 21 dermatologists in a similar task. This means a new experience in access to health.`,
    ProjPublicacoes: "Publications",
    Coordenacao: "Coordination",
    Gestao: "Management",
    PesquisadoresA: "Associated Researchers",
    PesquisadorA: "Associated Researcher",
    Pesquisadores: "Researchers",
    Resultados: "Results obtained",
    Alei: "Project ALEI - 2ª Instância",
    AleiTitulo: "The Intelligent Legal Analysis (ALEI)",
    Alei1aDescricao: "The project aims to structure the Artificial Intelligence Center for Justice to integrate the innovation chain of products and services in Artificial Intelligence applied to Justice, with the goal of developing alternatives for improving the Judiciary and fostering the growth of the technological innovation ecosystem in the Federal District, through research, training, and entrepreneurship.",
    ResAlei:
      "The Intelligent Legal Analysis (ALEI) project focuses on the digitization of TRF1 legal processes and aims to automate procedural stages of the second instance of TRF1.",
    AleiTxt1:
      "Treated by ALEI (Intelligent Legal Analysis), the project focuses specifically on the needs of 2nd Degree offices.",
    AleiTxt2:
      "Continuing the current phase of digitalization of TRF1 lawsuits, the ALEI - a research and development (R&D) project to be developed between TRF1 and GPAM - should use the base of digitized legal texts as an input for the development of technologies based on AI to automate procedural phases of the second instance of TRF1. To this end, it is proposed to systematize the TRF1 legal process database to create a “corpus” that will serve as the object of analysis of a system based on Machine Learning (AM) algorithms, including possible applications of Deep Neural Networks (DNN) , from the English Deep Neural Network).",
    AleiTxt3:
      "This project has a research object of great relevance in the national and international scenario, both from the point of view of the technique and its application. From the technique, it aims at the development of innovative technology in terms of pattern recognition methods in texts from the Artificial Intelligence area, specifically AM and NLP. From the application point of view, the use of AM / NLP methods in legal contexts also configures technology that is still little researched and explored in the international scenario. As Brazil has a scenario of hundreds of millions of processes, including high rates of delay / congestion, the field for the application of AM / NLP is very wide. ",
    AleiTxt:
      "Treated by ALEI (Intelligent Legal Analysis), the project focuses specifically on the needs of 2nd Degree offices. Continuing the current phase of digitalization of TRF1 lawsuits, the ALEI - a research and development (R&D) project to be developed between TRF1 and GPAM - should use the base of digitized legal texts as an input for the development of technologies based on AI to automate procedural phases of the second instance of TRF1. To this end, it is proposed to systematize the TRF1 legal process database to create a “corpus” that will serve as the object of analysis of a system based on Machine Learning (AM) algorithms, including possible applications of Deep Neural Networks (DNN) , from the English Deep Neural Network). This project has a research object of great relevance in the national and international scenario, both from the point of view of the technique and its application. From the technique, it aims at the development of innovative technology in terms of pattern recognition methods in texts from the Artificial Intelligence area, specifically AM and NLP. From the application point of view, the use of AM / NLP methods in legal contexts also configures technology that is still little researched and explored in the international scenario. As Brazil has a scenario of hundreds of millions of processes, including high rates of delay / congestion, the field for the application of AM / NLP is very wide.",
    EmAndamento: "In progress",
    Just40: "Pedro",
    Just40Descricao:
      "The project aims to develop an Artificial Intelligence solution to carry out the identification of similarity between demands and qualified precedents. The solution to be developed will aim to make available, in an orderly manner, the qualified precedents handed down by the superior courts, making it easier for the user, as much as possible, to retrieve the qualified precedents of interest for the solution of the specific case under analysis. In this way, the proposal of the work to be developed within the scope of this partnership is to develop an Artificial Intelligence solution that will carry out the search and analysis of qualified precedents, focusing on the superior courts (STJ and STF), so that the resulting algorithm is capable of to identify suitable qualified precedents for decision support in order to enable grouping by similarity.",
    Just40Title:
      "Pedro (JUSTICE 4.0) BRA/20/015 - INNOVATION AND EFFECTIVENESS IN ACHIEVING JUSTICE FOR ALL",
    Just40Conc:
      "Status: In progress; Nature: Research. Start year: 2021 - End year: -",
    Sabia: "Sabiá",
    SabiaDescricao:
      "The project's scope is to research and develop solutions to complement the Bem-Te-Vi system with regard to the functionalities of Process Grouping and Jurisprudence Survey. Aiming to show the real performance of these solutions, the objective of this work is to develop a module for User Feedback Registration. Finally, this project also aims to research Long Life Machine Learning (LLML) solutions in order to develop a solution that brings a long life cycle to the deployed AI models. In summary, this project aims to develop 2 (two) AI solutions, a performance recording system for these AIs and an LLML solution",
    SabiaAndamento:
      "Status: Done; Nature: Research. Start year: 2021 - 2024",
    ProjetosTxtEmDesenvolvimento:
      "Projects under development by the AI.lab team of researchers and professors",
    Osiris: "Osíris",
    OsirisTitle: "\"Artificial Intelligence in Tax Enforcement\" - Osíris",
    OsirisDescricao:
      "The project aims to research and develop solutions to assist the PGDF in managing demands related to tax enforcement proceedings, as well as to train its employees to maintain and enhance outcomes. The following challenges outline specific issues that the project should address:",
    OsirisLista:
      "Reading legal documents to identify procedural phases, comparing data for decision-making, and automating repetitive tasks;Automating the generation of legal petitions in tax enforcement proceedings within the automation systems of the Attorney General's Office of the Federal District, facilitated by the Deputy Attorney General's Office of the District Treasury (PGFAZ), through APIs (Application Programming Interface);Integrating technological applications into the automated systems of the Court of Justice of the Federal District and Territories (TJDFT), via the Tax Enforcement Court of the Federal District (VEF/DF), to suggest sequential judicial actions in response to repetitive statements from PGFAZ.",
    OsirisAndamento:
      "Status: In progress; Nature: Research. Start year: 2021 - Nowadays",
    Mandamus: "Mandamus",
    MandamusTitle: "Mandamus",
    MandamusDesc: "The TJRR, like all courts, faced challenges in promoting agility in the fulfillment of judicial mandates. MANDAMUS combined the sorting and distribution promoted by AI with the provision of an application to promote the work of officers. The CNJ works to distribute this solution to all courts.s"
  },
};

export default En;
